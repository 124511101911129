<template>
    <div>
        <van-nav-bar
        title="我的抵用券"
        left-arrow
        @click-left="onClickLeft"
        />
        <div style="padding:20px;background: #f9f9f9;">
          <van-pull-refresh v-model="isDownLoading" @refresh="onRefresh">
          <van-list
              v-model="isUpLoading"
              :finished="finished"
              finished-text="没有更多了"
              :offset=offset
              @load="onLoad"
              >
              <van-cell v-for="(item,index) in list" :key="item.id" class="list-item" :class="item.expire?'expire':''">
                <p v-if="item.couponStatus==0" class="expireBox"><span class="drop"></span><span style="font-size: 14px;">已失效</span></p>
                <div style="width:70%;display:inline-block;">
                  <p><strong style="font-size:16px">{{item.couponType==1?'代金券':''}}</strong><strong style="font-size:24px;color:#ac931b;margin-left:10px">{{item.couponAmount?item.couponAmount:0}}元</strong></p>
                  <p class='grayword'>到期时间<span style="margin-left:10px">{{item.expiryDate }}</span></p>
                </div>
                <div style="style=width:25%;display:inline-block;">
                  <!-- <img :src="item.img" alt=""  style="width:70px"> -->
                  <div  :id="'qrcode'+index"></div>
                </div>
              </van-cell>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
</template>
<script>
import QRCode from "qrcodejs2";
import {cardCoupon} from "@/api/base";
import func from '@/lib/commonfun';
import { Notify } from 'vant';
export default {
  name: 'mycertificate',
  data(){
    return {
        isUpLoading:false,//上拉加载
        isDownLoading:false,//下拉刷新
        offset:100,//滚动条与底部距离小于 offset 时触发load事件
        finished:false,
        listParams:{
          pageNo:0,
          pageSize:10,
          cardId:1
        },
        list:[
          // {
          //   "couponId": 1,
          //   "cardId": 1,
          //   "couponType": 1,  // 优惠券类型(1:代金券)
          //   "couponQrCode": "二维码",
          //   "expiryDate": "2023-01-04 21:00:00", // 失效日期
          //   "usageDate": "2023-01-04 21:00:00", // 使用日期
          //   "couponStatus": 1, // 状态(1:生效, 0:失效)
          //   "createTime": '1704344678000'
          // }
        ],
    }
  },
  mounted(){
    // this.listParams.cardId=this.$route.params.cardId;
  },
  methods:{
    onLoad(){
      if(this.isDownLoading){return}
      if(!this.finished){
        this.listParams.pageNo++;
        this.isUpLoading=true;
        this.getList(this.listParams)
      }
    },
    getList(params){
      cardCoupon(params).then((res)=>{
        this.isDownLoading = false;
            this.isUpLoading=false;
          if(res.code==0){
            if(res.data && res.data.list){
              let list=res.data.list;
              this.list= [...this.list, ...list]
              this.list.forEach((item,index)=>{
                item.expiryDate=func.timestampToYMD(item.expiryDate)
                this.$nextTick(()=>{
                  this.qrCode(item.couponQrCode?item.couponQrCode:"http://hy.zzlying.com/mine/card/SZ880001",index)
                })
              })
              if(res.data.list.length<10){
                 this.finished=true;
              }
            }else{
              this.finished=true;
            }
          }else{
            this.finished=true
            Notify({ type: 'warning', message: res.msg });
          }
      },(err)=>{
        this.isDownLoading = false;
        this.isUpLoading=false;
        Notify({ type: 'warning', message: '网络错误' });
      })
    },
    onRefresh() {
        this.finished = false;
        this.listParams.pageNo=1;
        this.list = []
        this.isDownLoading = true;
        this.getList(this.listParams);
    },
    cellBind(e){
        console.log(e)
    },
    onClickLeft(){
      this.$router.go(-1)
    },
    qrCode(url,index){
        document.getElementById('qrcode'+index).innerHTML = '';
        var qrcode=new QRCode('qrcode'+index,{
            text:url,//要生成二维码的链接
            margin:15,
            quality:.5,
            width:70,//二维码的宽度
            height:70,//二维码的高度
            colorDark:'#000000',//前景色
            colorLight:'#ffffff',//背景色
            correctLevel:QRCode.CorrectLevel.H  //纠错等级
          })
          qrcode.clear() //清除二维码 
          qrcode.makeCode(url) //生成另一个新的二维码  
    },
  }
}
</script>
<style scoped>
.van-list{
  min-height: 100vh;
}
 .list-item{
    padding:20px;
    border-radius: 10px;
    margin-bottom: 20px;
    position: relative;
 }
 .list-item.expire{
  background-color: #d7d7d7;
 }
 .expireBox{
   position: absolute;
   top:-6px;
   left:50px;
 }
 .drop{
  display: inline-block;
  width:8px;
  height:8px;
  background: #969799;
  border-radius: 50%;
  margin-right:10px;
 }
</style>